.body_presentation { 
    margin: 50px 0;
    h2 { 
        font-weight: 600;
        font-size: 53.9172px;
        line-height: 65px;
        width: 50%;
        margin-left: 80px;
    }
    p { 
        font-weight: 400;
        font-size: 24px;
        line-height: 43px;
        width: 90%;
        margin-bottom: 20px;
    }
}

.presentation {
    margin-bottom: 150px;
}