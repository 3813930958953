.contact-us__lets-start {
  margin: 70px 0 100px;
}

.contact-us__lets-start-heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
}
.contact-us__lets-start-content {
  font-size: 20px;
  line-height: 30px;
  color: var(--main-text-color);
}
.contact-us__lets-start-mail {
  font-weight: bold;
  text-decoration: underline;
  color: var(--main-text-color);
  margin-left: 3px;
}
@media (max-width: 992px) {
  .contact-us__lets-start-heading {
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
}
@media (max-width: 426px) {
  .contact-us__lets-start-content {
    font-size: 20px;
  }
  .contact-us__lets-start {
    margin-bottom: 60px;
  }
}

@media (max-width: 376px) {
  .contact-us__lets-start-heading {
    font-size: 35px;
    margin-bottom: 0px;
    text-align: left;
    line-height: 45px;
    padding: 0px 8px;
  }
  .contact-us__lets-start-content {
    display: none;
  }
}
