.scope__of__work {
    margin: 100px 0 150px;  
    .scope_parag {
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
    }
    @media (max-width: 600px) {
        padding: 0 20px;
        margin-bottom: 0px;
    }
}
.scope_card {
    padding: 50px 30px;
    border: 1px solid rgb(236, 236, 236);
    margin: 0px 0 50px 0;
    height: 370px;
    img { 
        width: 70px;
        height: 70px;
        object-fit: fill;
    }
    >h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 20px;
    }
    >p {
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0px;
        text-align: left;
        width: 90%;
        margin-bottom: 0;
    }
}

.card-swiper {
    padding-top: 20px;
    padding-bottom: 0px;
}
.swiper-scrollbar {
    height: 3px !important;
    width: 100% !important;
    left: 0 !important;
    border-radius: 0 !important;
}
