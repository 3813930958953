.testmonialsLearnui{
    background-color:#f6fcf7;
    padding: 100px 0;
}
.testmonialsLearnui .header-testmonials h2{
    color: #23262B;
    font-family: "Cairo", Sans-serif;
    font-weight: 600;
    font-size: 48px;
}
.testmonialsLearnui .header-testmonials p{
    font-family: "Cairo", Sans-serif;
    font-weight: 400;
    color: #23262B;
    font-size: 18px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 20px;
}
@media only screen and (max-width: 528px) {
    .testmonialsLearnui{
        margin: 0 !important;
        padding: 10px !important;
    }
    .testmonialsLearnui .header-testmonials h2{
        font-size: 32px !important;
        line-height: 42px !important;
    }
}