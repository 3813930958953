.hide {
  display: none !important;
}
.contact-us__deck {
  margin-bottom: 190px;
}
.contact-us__deck-heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 20px;
}
.contact-us__deck-content {
  font-size: 20px;
  color: #666666;
  width: 60%;
  line-height: 30px;
}

.contact-us__submit-form {
  background-color: var(--main-color);
  color: rgb(0, 0, 0);
  text-decoration: none;
  border: 0;
  font-weight: bold;
  border-radius: 30px;
  width: 240px;
  height: 50px;
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-us__submit-form:hover {
  animation: ButtonAnimation 0.3s linear 1;
  color: white;
}
.contact-us__submit-form-cont {
  margin-top: auto;
}
.contact-us__deck-img-cont {
  min-height: 400px;
  overflow: hidden;
  max-height: 400px;
  transition: var(--transition);
}
.contact-us__deck-img-cont img:hover {
  transform: scale(1.2);
}

@media (max-width: 992px) {
  .contact-us__deck-heading {
    font-size: 30px;
    line-height: 20px;
    margin-bottom: 30px;
    text-align: center;
  }
}
@media (max-width: 426px) {
  .contact-us__deck-content {
    font-size: 20px;
    margin-bottom: 50px;
    margin-top: 20px;
  }
}

@keyframes ButtonAnimation {
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 376px) {
  .contact-us__deck {
    margin-bottom: 0px;
    padding-right: 8px;
    padding-left: 8px;
  }
  .contact-us__deck-heading {
    padding-top: 80px;
    text-align: left;
    font-size: 35px;
    margin-bottom: 0px;
  }
  .contact-us__deck-content {
    width: 100%;
    margin-bottom: 30px;
  }
  .contact-us__deck-img-cont {
    min-height: 270px;
  }
  .contact-us__submit-form-cont {
    display: none;
  }
  .hide {
    margin-top: 30px;
    display: block;
    width: 100%;
  }

  .contact-us__submit-form {
    margin-top: 30px;

    width: 100%;
  }
}
