.need_layouti {
    padding: 100px 0;
    padding-top: 0;
    margin-bottom: 50px;
    @media only screen and (max-width: 600px) { 
        padding-bottom: 50px;
    }
    .need_layouti_cards_wrap {
        margin-top: 80px;
        @media only screen and (max-width: 600px) { 
            margin-top: 0px;
        }
    }
    .need_layouti_card_title{
        width: 80%  ;
        @media only screen and (max-width: 600px) { 
        width: 100%  ;   
            font-size: 35px !important;
            line-height: 45px !important;
            margin-bottom: 0;
        }
    }
    .need_layouti_card_p{
        @media only screen and (max-width: 600px) { 
            display: none;
        }
    }
    @media (max-width: 600px) { 
        padding: 0 20px 0 8px;
    }
}

.need_layouti_card  {
    img { 
        @media only screen and (max-width: 600px) { 
            display: none;
        }
    }
}
