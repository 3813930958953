.indepth_overlay { 
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.322);
    z-index: 9999;
    display: none;
    &.active { 
        display: block;
    }
}
.indepth_inner{
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    height: 100vh;
    overflow-y: scroll;
    background-color: #fff;
    z-index: 9999;
    transition: right 0.3s linear;
    &::-webkit-scrollbar{
        display: none;
    }

    &.active { 
        right: 0;
    }
    .indepth_left {
        border-right: 1px solid rgb(214, 214, 214);
        height: unset;
        h3 { 
            margin: 60px 0 50px 50px;
            font-size: 18px;
            font-weight: 400;
        }
        h1 { 
            font-size: 35px;
            margin: 80px 40px 50px 50px;
        }
    }
    .indepth_right {
        height: 100%;
        margin-top: 90px ;
        padding: 40px;
        .indepth_cards {
            margin-bottom: 10px;
            .indepth_cards_info { 
                margin-bottom: 40px;
            }
        }
        h1 { 
            font-size: 35px;
            margin-top: 0;
            padding-top: 35px;
        }
        p { 
            font-size: 18px;
            line-height: 1.6;
            width: 90%;
            margin-bottom: 10px;
        }
        img { 
            width: 120%;
            margin-left: -200px;
            margin-bottom: 50px;
        }
    }
 }