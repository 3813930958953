.overlay {
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: fixed;
    width: 100%;
    display: block;
    z-index: 9999;
    top: 110px;
    height: 100%;
}
.products_layouti {
    background-color: #F9F9FC;
    position: fixed;
    width: 100%;
    display: block;
    z-index: 9999;
    top: 110px;
    height: 400px;

    .products_layouti_body {
        height: 400px;
    }
    .products_layouti_cont {
        height: calc(100% - 67px);
    }
    .products_layouti_head {
        text-align: center;
        padding: 16px;
        border-bottom: 1px solid #DFE5EE;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F9F9FC;
        p {
            margin: 0;
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            color: #00122F;
        }
    }
    .products_layouti_cards {
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        border-right: 1px solid #DFE5EE;
        background-color: #ffffff;
        img {
            width: 100%;
        }
        &:first-of-type {
            background-color: #F9F9FC;
            >a{ 
                color: #12BD0B; 
            }
        }
        &:last-of-type {
            background-color: #F9F9FC;
            >a{ 
                color: #12BD0B; 
            }
        }
        >p {
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            width: 90%;
        }
        .link_btn {
            width: 131px;
            height: 42px;
            background: #00CCFF;
            border-radius: 50px;
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 26px;
            text-align: center;
            color: #00122F ;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
        }
        .products_layouti_cards_head {
            h2 {
                font-family: 'Cairo';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 26px;
            }
            p {
                font-family: 'Cairo';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #727985;
            }
        }
    }
}

.products_layouti_cards_text {
    padding-left: 10px;
    height: 100%;
    display: flex;
        flex-direction: column;
        justify-content: space-between;
}