.sidebar {
    display: block;
    position: fixed;
    right: -100%;
    top: 0;
    color: #fff;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    transition: all 0.3s linear;
    @media only screen and (max-width: 600px)  {
        transition: all 0.2s linear;
        padding-top: 5px !important;
    }
    &.active {
        right: 0;
    }
}

.sidebar_container {
    @media only screen and (max-width: 600px)  {
        padding:  20px 0 0 0 !important;
    }
}

.side_header {
    height: 110px;
    @media only screen and (max-width: 600px)  {
        padding: 0 20px;
        height: 40px;
        .side_header_title {
            display: none;
        }
    }
}

// .main__manue{

// }


.logo{
    @media only screen and (max-width: 600px)  {
  padding-left: 10px;
    }
}

.toggle_close {
    background-color: transparent;
    color: #fff;
    font-size: 28px;
    border: none;
    padding: 0;
    margin: 0;
    float: right;
    @media only screen and (max-width: 600px)  {
        padding: 0px !important;
        margin: 0px !important;
   
    }
}

.side_navbar_one {
    padding: 0;
    margin: 0;
}

.side_nav_item {
    padding-bottom: 30px;
}

.side_nav_item_link {
    color: #fff !important;
    font-size: 20px;
    font-weight: 500;
    transition: all 0.2s linear;
    &:hover {
        text-decoration: underline;
        color: #3AAA35  !important;
    }
    &.active {
        text-decoration: underline;
        color: #3AAA35  !important;
        font-weight: 700;
    }
}


.side_navs_link {
    color: rgba(255, 255, 255, 0.678) !important;
    padding: 0 !important;
    margin: 0;
    transition: all 0.2s linear;
    &:hover {
        text-decoration: underline;
        color: #3AAA35  !important;
    }
}
.side_navs {
    padding: 0;
    margin: 0;
    li {
        padding-bottom: 10px;
    }
}


.sideBar_main_links{
    color: #fff !important;
    text-decoration: none;
    font-size: 1.75rem !important;
    font-weight: 700 !important;
    transition: all 0.2s linear;
    line-height: 45px;
    position: relative;
    &::before{
        content: '';
        position: absolute;
        width: 0%;
        height: 2px;
        bottom: 10%;
        background-color: #3AAA35;
        left: -5%;
        transition: all .3s;
    }
    &:hover {
        color: #3AAA35 !important;
        text-decoration: none!important;
        &::before{
          content: '';
          width: 110%;
      }
      
    }
    &.active{
        &::before{
            content: '';
            position: absolute;
            width: 110%;
            height: 2px;
            bottom: 10%;
            background-color: #3AAA35;
            left: -5%;
            transition: all .3s;
        }
      }
      &.active {
        // text-decoration: underline;
        color: #3AAA35  !important;
    }
    li{
        margin-bottom: 5px;
    }
    
}

.footer_navs_link {

    &.active {
        // text-decoration: underline;
        color: #3AAA35  !important;
    }
}
.sidebar-menu__logo{
    width: 66px;
    height: 66px;
}