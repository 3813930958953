.about-features{
    padding: 100px 0 120px 0;
    background-color: #F7F9FC;
    transition: all .25s linear;
    @media(max-width:426px){
        padding: 70px 20px 40px ;
        .container  {
            padding: 0;
        }
    }
    
    > *{
        transition: all .25s linear;
    }
}
.about-features__title{
    font-size:40px;
    line-height:50px;
    color:#23262B;
    margin-bottom: 80px;
    font-weight: 700;
    width: 50%;
    @media(max-width:768px){
        width: 80%;
    }
    @media(max-width:426px){
        width: 100%;
        margin-bottom: 50px;
        font-size: 36px;
        line-height: 45px;
    }
}