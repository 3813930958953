.team-section{
    background-color: white;
    transition: all .25s linear;
    margin-bottom: 80px;
    .team-section-cont{
        transition: all .25s linear;
        @media (max-width:576px) {
            padding: 0px 20px;
        }
    }
    > *{
        transition: all .25s linear;
        // opacity: 1;

    }
    @media (max-width:576px) {
        margin-bottom: 20px;

    }
}



.empolyee-card{
    @media (max-width:576px) {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin-bottom: 20px !important;
        h4 { 
            margin-bottom: 0;
            text-align: end;
        }
    }
    img{
        transition:all .25s ease-in-out;
        &:hover{
            transform:scale(1.1)
        }
    }
}
.about-text--margin{
    margin-bottom: 80px;
    @media (max-width: 600px ) {
        margin-bottom: 40px;
    }
}
.about-text--margin img{
    transition:all .25s ease-in-out;
    &:hover{
        transform:scale(1.1)
    }
}

.about-img-cont--first{
    max-width: 650px;
    max-height: 364px;
    min-height: 364px;
    overflow: hidden;
    img{
        min-height: inherit;
    }
}
.about-img-cont{
    max-width: 350px;
    min-height: 364px;
    overflow: hidden;
    img{
        min-height: inherit;
    }
}
.empolyee-img-cont{
    width:245px ;
    min-height:245px ;
    @media (max-width: 600px ) {
        width: unset;
    }
}