

.works_details_title {
    width: 95%;
    font-size: 40px;
    line-height: 47px;
    margin-top: 20px;
}

.slogan {
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    margin-top: 20px !important;
}

.Launch_parag {
    font-size: 18px;
    margin: 0;
}

.works_info {
    font-size: 18px;
    margin: 0;
}

.works__desc {
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    margin-bottom: 20px;
}

.people__title {
    font-size: 18px;
    font-weight: 400;
}

.indepth {
    padding-top: 40px;
    margin-top: 40px !important;

    font-size: 18px;
    .indepth_title {
        font-weight: 700;
        line-height: 37px;
    }
    h3 { 
        font-size: 18px;
    }
}