.content-learnui {
  background-image: url("https://learnui.layouti.com/wp-content/uploads/2021/10/Banner.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 530px;
  position: relative;
  padding: 100px 0 0 0;
  margin-bottom: 200px;
  margin-top: 110px;
}

.content-learnui .background-overlay-learnui {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #181818;
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.content-learnui .content-ui {
  //   position: absolute;
  //   z-index: 5;
  color: #fff;
  width: 100%;
}
.content-learnui .content-ui .text {
  height: 400px;
  position: relative;
}

.content-learnui .content-ui .text h2 {
  font-family: "Cairo", Sans-serif;
  width: 100%;
}

.content-learnui .content-ui .text h2 {
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 0.2px;
}
.content-learnui .content-ui .text h4 {
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0.2px;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 35px;
}
.content-learnui .content-ui .text p {
  color: #c5c5c5;
  font-size: 21px;
  font-weight: 300;
  line-height: 35px;
  margin-bottom: 20px;
}
.content-learnui .content-ui .sty-link {
  padding-bottom: .3rem;
}
.content-learnui .content-ui .text .icons-content {
  position: absolute;
  bottom: 5px;
  width: 100%;
}
.content-learnui .content-ui .text .icons-content i {
  font-size: 20px;
}
.content-learnui .content-ui .text .icons-content a {
  text-decoration: underline;
  cursor: pointer;
  color: #fff;
}
.content-learnui .content-ui .text .icons-content span {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
}
.content-learnui .img-iconr {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
.highlight {
  padding: 100px 0;
}
.highlight h2 {
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 50px;
  color: #23262B;
  line-height: 54px;
}
.content-learnui .content-ui .form {
  border-radius: 30px;
  background-color: #fff;
  position: absolute;
  z-index: 5;
  min-height: 600px;
  width: 30%;
  margin-left: 25px;
  transform: translateY(-50px);
  color: #23262B;
  border: 1px solid #d8d8d8;
}
.form form {
  padding: 30px 40px;
}
.success-errMs  {
  background-color: transparent;
  position: fixed;
  top: 20%;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
}
.success-errMs .success-errMs-content {
  background-color: #fff;
  border-radius: .8rem;
  width: 25rem;
  min-width: auto;
  height: auto;
  margin: auto;
  text-align: center;
  padding: 1rem;
  color: #23262B;
}
.success-errMs .success-errMs-content h2{
  color: #42BF64;
}
.success-errMs .success-errMs-content .popBtn{
  color: #23262B;
  border-radius: 2rem;
  padding: .5rem 6rem;
  background-color: transparent;
  border: #42BF64 .1rem solid;
}
.success-errMs .success-errMs-content .popBtn:hover{
  color: #fff;
  border-radius: 2rem;
  padding: .5rem 6rem;
  background-color: #42BF64;
}
.form .borderForm {
  border: 1px solid #42bf64;
  border-radius: 30px;
  height: 50px;
  margin-bottom: 10px;
  position: relative;
}
.form .borderForm input,
.form .borderForm select {
  border: none;
  outline: none;
  position: absolute;
  // border: 1px solid #42BF64;
  margin: auto;
  height: 100%;
  border-radius: 30px;
  padding: 5px 20px;
  color: #646e83 !important;
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
}
.form .form-control:focus,
.form select:focus {
  box-shadow: none !important;
}
.form ::placeholder,
.form .borderForm select option {
  color: #646e83 !important;
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
}
.form .mobileBorder {
  border: 1px solid #42bf64;
  border-radius: 30px;
  height: 50px;
  margin-bottom: 10px;
  position: relative;
}
.form .mobileBorder .PhoneInput {
  border-radius: 30px;
  padding: 5px 20px;
  border: none;
  outline: none;
  position: absolute;
  height: 100%;
  width: 100%;
}
.form .mobileBorder .PhoneInput input {
  border: none;
  outline: none;
}
.form .register {
  width: 100%;
  border: 1px solid #42bf64;
  border-radius: 30px;
  height: 50px;
  margin-bottom: 10px;
  background: #42bf64;
  color: #fff;
  margin-top: 15px;
  font-size: 16px;
}
.form .register:hover {
  animation: ButtonAnimation 0.3s linear 1;
  background: #42bf64;
}
.form label {
  font-size: 14px;
  color: #23262b;
  font-weight: 400;
  line-height: 25px;
}
.form .headerForm {
  border-bottom: 1px solid #e6ebf4;
  padding-bottom: 20px;
  padding-top: 20px;
}
.form .headerForm h2 {
  color: #181818;
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
  margin-left: 35px;
  margin-right: 35px;
  margin-top: 10px;
}
.form .star-form {
  color: red;
  font-size: 14px;
}

@media (max-width: 375px) {
  
  .header_one {
    padding: 10px 20px;
    // height: fit-content;
  }
  .container {
    width: 100% !important;
    padding: 0 20px;
  }
  .form .headerForm h2 {
    font-size: 30px;
    line-height: 38px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .elementor-7949
    .elementor-element.elementor-element-8902ba9
    .elementor-heading-title {
    font-size: 36px;
  }
  .content-learnui .content-ui .form {
    bottom: -213px;
    height: fit-content !important;
    form {
      padding: 20px;
      .mb-3 {
        margin-bottom: 0.6rem !important;
        .form-label {
          margin-bottom: 0.2rem;
        }
      }
    }
  }
  .userInterface-content {
    margin: 0;
    .container {
      padding: 0 !important;
    }
  }
  .testmonialsLearnui {
    margin: 0;
  }
  .powerCardsLearnui {
    .cards {
      padding-top: 0;
      margin-top: 0;
    }
  }
  .steps {
    margin: 50px 0 !important;
  }
  .questionsLearnui {
    margin: 0 !important;
  }
  .footer {
    margin-top: 0;
    padding-top: 0;
  }
}
@media only screen and (max-width: 1100px) {
  .content-learnui {
    display: flex;
    flex-direction: column;
    padding: 50px 0 0 0;
  }
}
@media only screen and (max-width: 992px) {
  .content-learnui .content-ui .text h2 {
    font-size: 45px !important;
  }
  .content-learnui .content-ui .text h4 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    line-height: 40px !important;
  }
  .content-learnui .content-ui .text .icons-content {
    position: relative !important;
  }
  .content-learnui .content-ui .form {
    width: 80%;
    transform: translate(50% ,-70px);
    right: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .content-learnui {
    min-height: 940px;
    margin-bottom: 150px;
  }
  .content-learnui .content-ui .form {
    // margin-top: 30px;
    transform: translate(50% ,50px);
  }
  .content-learnui .content-ui .text h4 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}

@media only screen and (max-width: 528px) {
  .content-learnui {
    min-height: 1034px;
    margin-bottom: 151px;
  }

  // .content-learnui .content-ui .form {
  //   width: 93% !important;
  //   margin: 0 !important;
  //   height: 630px !important;
  //   bottom: -320px;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
  .content-learnui .content-ui .form button {
    width: 100%;
    padding: 0 40px;
    margin-bottom: 20px;
  }
  // .content-learnui .content-ui .text{
  //     margin: 0 20px;
  // }
  .content-learnui .content-ui .text {
    h2 {
      font-size: 45px !important;
      line-height: 56px !important;
    }
    h4 {
      font-size: 36px !important;
      line-height: 46px !important;
    }
    p {
      font-size: 21px !important;
      line-height: 35px !important;
    }
    }
  }
  .info {
    margin-bottom: 15px;
    display :flex;
    align-items:center;
    svg {
      font-size: 21px;
    }
  .highlight h2 {
    font-size: 32px !important;
    line-height: 42px !important;
    margin: 0px 20px 10px !important;
  }
  .my-masonry-grid {
    margin: 20px 20px;
    padding: 0 20px;
  }
}
@media only screen and (max-width: 426px) {
  .content-learnui{
    min-height: 900px;
  }
  .content-learnui .content-ui .text h2{
    margin-bottom: 20px;
    font-size: 30px !important;
    line-height: 36px !important;
  }
  .content-learnui .content-ui .text h4{
    font-size: 26px !important;
    line-height: 36px !important;
  }
  .content-learnui .content-ui .text p{
    font-size: 17px !important;
    line-height: 31px !important;
  }
  .content-learnui .content-ui .form{
    transform: translate(50%, 30px);
    width: 90%;
  }
  .form form{
    padding: 10px 15px;
  }
  .headerForm{
    padding-bottom: 0; 
    padding-top: 20px;
  }
  .form .headerForm h2{
    padding: 10px 15px;
    margin: 0;
  }
}
@media only screen and (max-width: 375px) {
  
  .content-learnui {
    margin-top: 0;
  }
}

@keyframes ButtonAnimation {
  50% {
    transform: scale(0.8);
    }
  100% {
      transform: scale(1);
  }
}