.cv_header_container {
    position: relative;
    .cv_header {
        img {
            display: block;
            width: 230px;
            height: 230px;
            margin: auto;
            
        }
        h3 {
            @media only screen and (max-width: 600px) {
                padding: 0 20px;
            }
        }
        p {
            width: 55%;
            margin: 0 auto;
            @media only screen and (max-width: 600px) {
                width: 90%;
            }
        }
    }
    .absolute__imgs {
        position: absolute;
        opacity: 0.2;
        transition: all 0.4s linear;
        border-radius: 10px;
        &:hover {
            opacity: 1;
        }
        &:first-of-type {
            right: 100px;
            top: 0;
            width: 200px;
        }
        &:nth-of-type(2) {
            left: 0;
            top: 0;
            width: 200px;
        }
        &:nth-of-type(3) {
            left: -100px;
            bottom: 0;
            width: 150px;
        }
        &:nth-of-type(4) {
            right: -100px;
            bottom: 0;
            width: 150px;
        }
        @media only screen and (max-width: 600px) {
            display: none;
        }
    }    
    .emoji__imgs {
        position: absolute;
        transition: all 0.4s linear;
        width: 80px;
        top: 0;
        right: 0;
        //animation: trans 3s linear infinite ;
        // animation-delay: 2s;
        &:last-of-type {
            left: 50px;
            top: 90% ;
           // animation: trans 3s linear infinite reverse !important;
            // animation-delay: 2s;
        }
        @media only screen and (max-width: 600px) {
            display: none;
        }
    }    
}


.social_media_cv {
    margin-top: 100px;
    font-size: 30px;
    font-weight: 700;
}

.social_media_link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    width: 550px;
    height: 84px;
    font-weight: 400;
    border: 2px solid #3AAA35;
    border-radius: 10px;
    font-size: 20px;
    transition: all 0.2s linear;
    &:hover {
        background-color: #3AAA35;
        color: #fff;
    }
    @media only screen and (max-width: 600px) {
        width: 90%;
        // margin: 20px;
    }
}


@keyframes trans {
    0% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-50px);
    }
    50% {
        transform: translateY(-50px);
    }
    100% {
        transform: translateY(0px);
    }
}