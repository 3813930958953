.searchbar {
    position: fixed;
    top: -110px;
    left: 0;
    display: block;
    width: 100%;
    height: 110px;
    background-color: #fff;
    border-bottom: 1px solid #EAEAEA;
    transition: all 0.2s linear;
    z-index: 99999;
    &.active {
        top: 0;
    }
    @media only screen and (max-width: 600px)  {
        padding: 0 20px!important;
    }
}

input[type=search] {
    &::placeholder {
        color: #939CAB;
    }
}


.close_search {
    background-color: transparent;
    border: none;
}