.layouti_proccess_desgin {
    margin: 100px auto;
    padding: 150px 0;
    color: #fff;
    background-color: #00122f !important;
    margin-bottom: 0;
    transition: all .25s linear;
    background-color: white;
    > *{
        transition: all .25s linear;
    }
   
    @media only screen and (max-width: 600px)  {
        padding: 100px 8px;
    }
    .layouti_proccess_desgin_cont{
        .layouti_proccess_desgin_head{
            .col {
                >h1 {
                    font-size: 42px;
                    font-weight: 700;
                    @media only screen and (max-width: 600px)  {
                        font-size: 36px;
                    }
                }
                >p {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;
                    width: 80%;
                }
                .layouti_proccess_desgin_head-para{
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;
                    @media only screen and (max-width: 600px)  {
                        display: none;
                    }
                }
                @media only screen and (max-width: 600px)  {
                    .parag_cont_home {
                        display: none;
                        
                    }
                    h1 { 
                        font-size: 36px;
                    }
                }
            }
        }
    }
}
.layouti_proccess_card-para{
    margin-bottom: 10px;
    transition: all .25s linear;
    @media only screen and (max-width: 600px)  {
        font-size: 18px;
    }
}

[data-aos='scroll-bg-animation']{
    background-color: white;
    // color: white;
    transition: all .5s .5s linear;
    
    
  &.aos-animate {
    background-color: #23262B;
}
}

.layouti_proccess_desgin_head-para{
    @media only screen and (max-width: 600px)  {
        display: none;
    }
}


.layouti_proccess_card {
    h1 { 
        @media only screen and (max-width: 600px)  {
            font-size: 35px;
        }
    }
    img {
        @media only screen and (max-width: 600px)  {
            display: none ;
        }  
    }
   
}

.layouti_proccess_desgin_head_h1 {
    font-size: 50px;
    padding-right: 50px;

    @media only screen and (max-width: 600px)  {
        font-size: 35px;
        line-height: 45px !important;
    }
}


@media only screen and (max-width: 600px)  {
    .parag_cont_home {
        display: none;
        
    }
    h1 { 
        font-size: 36px;
    }
}