.category-wrapper{
    margin-bottom: 50px;
    padding: 80px 0;
    position: relative;
    overflow-x: hidden;
}
.category-wrapper:before{
    content: '';
    top:0;
    bottom: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background-color: #F9FBFD;
    position: absolute;
    z-index: -1;
}

.category-header__title{
    font-size: 32px;
    // line-height: 60px;
    font-weight: 700;
    margin-bottom: 10px;
}
.category-header__description{
    width: 40%;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 50px;
}
.category-swiper{
    cursor: grab;
}
.category__slider-wrapper{
    padding: 0 140px;
}
.category-wrapper .swiper{
    overflow: initial!important;
}
@media (max-width:1024px) {
    .category-header__description{
        width: 60%;
    }
    .category__slider-wrapper{
        padding: 0 80px;
    }
    .category-header__wrapper{
        padding: 0 40px;
    }
}
@media (max-width:768px) {
    .category-header__description{
        width: 80%;
    }
    .category__slider-wrapper{
        padding: 0 80px;
    }
    .category-header__wrapper{
        padding: 0 45px;
    }
}
@media (max-width:426px) {
    .category-header__description{
        width: 100%;
    }
    .category__slider-wrapper{
        padding: 0 30px;
    }
    .category-header__wrapper{
        padding: 0 20px;
    }
    .category-wrapper .swiper-slide{
        // margin-right:0px!important ;
    }
    .category-wrapper .swiper-slide:first-of-type{
        // margin-right:-40px!important ;
    }
    .category-item{
        width: 100%;
    }
}