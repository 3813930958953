.header_layouti {
    height: 110px;
    border-bottom: 1px solid transparent;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.2s ease-in-out;
    z-index: 4000;
    &.active {
        top: -110px;
    }
    &.white {
        background-color: #fff;
        border-bottom: 1px solid #EAEAEA;
        @media only screen and (max-width: 375px)  {
            // background-color: #23262b;
            // .toggle-white-icon path{
            //     fill:white
            // }
        }
    }
    @media only screen and (max-width: 600px)  {
        padding: 0 20px;
    }
    @media only screen and (max-width: 175px)  {
        background-color: #ffffff !important;
    }
    .container {
        .row{
            .navbar_one {
                list-style: none;
                display: flex;
                justify-content: space-between;
                padding: 0;
                align-items: center;
                margin: 0;
                .navs {
                    white-space: nowrap;
                    font-weight: 400;
                }
            }
        }
    }
    
}


.nav_item_link{
    transition: all 0.2s linear;
    font-size: 14px;
    position: relative;
    color: #000;
    text-decoration: none;
    font-weight: 400;
    &::before{
        content: '';
        position: absolute;
        width: 0%;
        height: 2px;
        top: 80% !important;
        background-color: #3AAA35;
        left: -15%;
        transition: all .3s;
    }
    &.white__link {
        color: #fff
    }
    &:hover {
        color: #3AAA35 !important;
        text-decoration: none!important;
        &::before{
            width: 125%;
        }
    }
    &.active {
        color: #3AAA35 !important;
        text-decoration: none!important;
        font-weight: 700;
        &::before{
            content: '';
            position: absolute;
            width: 150%;
            height: 2px;
            top: 80% !important;
            background-color: #3AAA35;
            left: -25%;
        }
    }
}
.navs {
    @media only screen and (max-width: 600px)  {
        display: none !important;
    }
}
.nav_item_btn {
    a {
        background-color: #26e71e;
        color: #000000;
        font-weight: bold;
        border-radius: 20px;
        font-size: 14px;
        width: 148px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            color: #000000 !important;
            text-decoration: none;
            animation: ButtonAnimation 0.3s linear 1;
            &::before{
                display: none;
            }
        }
        @media only screen and (max-width: 600px)  {
            width: 100%;
            height: 45px;
        }
    }
}
@keyframes ButtonAnimation {
  50% {
    transform: scale(0.8);
    }
  100% {
      transform: scale(1);
  }
}
.toggle-btn {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
    float: right;
    @media only screen and (max-width: 600px)  {
        display: block;
        margin-left: 20px;
    }
}
.logo-icon path{
    transition: all .3s ease-in-out;
}
.menu-icon path{
    transition: all .3s ease-in-out;
}
.dark-menu path{
    fill:#23262b
}
.dark-logo path:first-of-type{
    fill:#23262b
}
.dark-logo path:last-of-type{
    fill:#23262b
}
.toggle-btn--lang{
    font-size: 14px;
    color: white;
}
.toggle-btn--lang.dark-Lang{
    color: #000
}

.header_logo {
    @media only screen and (max-width: 600px)  {
        margin-left: 0px;
    }
}

