.cv-content{
    padding-top: 150px;
    width: 90%;
    margin: auto;
}

.cv-cover__title{
    font-size: 21px;
    color: #181818;
    line-height: 21px;
    font-weight: 700;
}
.cv-experience__list{
    margin: 0;
    padding: 0;
    top: 150px !important;
    height: fit-content;
}
.cv-experience__item{
    margin-bottom: 20px;
}
.cv-experience__link{
    font-size:20px;
    line-height:30px;
    color: black;
    font-weight:600;
    transition: all .3s ease-in-out;
    background: transparent;
    border: 0;
    outline: 0;
}
.cv-experience__link:hover{
    color:#42BF64
}
.cv-experience__link.active{
    color:#42BF64
}

.cv-cover__desc{
    color:#181818;
    font-size:18px;
    line-height:26px;
    margin-bottom: 50px;
}

.cv-sections__cont{
    padding: 40px 0;
    border-bottom: 1px solid #dcd5dc ;
}
.cv-sections__cont:first-of-type{
    padding-top: 0px;
}
.cv-sections__cont:last-of-type{
    border-bottom: none ;
}
.cv-interest__para{
    color: #181818;
    font-size: 21px;
    line-height: 20px;
    font-weight: 600;
}
.cv-skills__skill-cont:not(:last-child){
    margin-bottom: 30px;
}

.cv-skills__rate-icon{
    width: 24px;
    margin-right: 15px;
}
.cv-skills__rate-icon path{
    fill:white;
    stroke: #181818;
}
.cv-skills__rate-icon.filled path{
    fill:#42BF64;
    stroke: none;
}

.cv-skills__skill-title{
    font-size: 24px;
    line-height: 36px;
    color: #181818;
    font-weight: 600;
    margin: 0;
}

.cv-categoreis__year--courses{
    color: #181818;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    font-style: italic;
    margin: 0;
}
.cv-courses__content{
    font-size:24px;
    line-height:36px;
	color:#181818;
    font-weight:500;
}
.cv-courses__content--para{
    margin: 0;
}