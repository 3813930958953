.products__header {
    width: 100%;
    height: 450px;
    margin-bottom: 60px;
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media only screen and (max-width: 600px) {
        height: 250px;
    } 
}

.img_prod {
    display: block;
    margin: 60px 0;
    border-radius: 8px;
    width: 100%;
    height: 280px;
    cursor: pointer;
    // object-fit: contain;
    @media only screen and (max-width: 600px) {
        margin-right: 20px;
        margin-left: 20px;
        width: 90%;
        height: unset;
    }
}
.prod-card{
    border-radius: 8px;
    background-color: #F9FBFD;
    // padding: 30px;
    border: 1px solid #CEDBE4B2;
    width: 75.3%;
    margin:0 auto 60px;
    height: 20rem;
    @media only screen and (max-width: 600px) {
        min-height: 60vh;
        // padding: 20px;
    }
}
.project-col-cont{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    
    margin-bottom: 60px;
    @media(max-width:426px){
        margin-bottom: 20px;
        padding: 0 20px !important;
    }
}

.prod-card__content-cont{
    // width: 80%;
    border-right: 1px solid #E3EAF4;
}
.prod-card__logo {
    width: 11%;
    border-radius: 0;
    border: none;
}
.prod-card__logo-cont{
    display: flex;
    margin-bottom: 20px;
}
.prod-card__project-info{
    margin-left: 20px;
}
.prod-card__project-title{
    font-size: 20px;
    font-weight: 700;
    color: #23262B;
    margin-bottom: 5px;
}
.prod-card__project-link:hover{
    color:#23262B ;
    text-decoration: underline;


}
.prod-card__project-link{
    color:#23262B ;
    text-decoration: underline;

    @media(max-width:600px){
        text-decoration: none !important;
    
    }

}


.prod-card__project-field{
    color: #23262B;
    font-size: 14px;
    font-weight: 400;
}
.prod-card__desc{
    font-size: 14px;
    color: #23262B;
    margin-bottom: 50px;
    width: 80%;
}
.prod-card__actions-cont{
    margin-bottom: 30px;
    margin-top: auto;
}
.prod-card__action{
    padding: 10px 25px;
    border-radius: 8px;
    border: 0;
    outline: 0;
    font-size: 14px;
}
.prod-card__action--disable{
    background-color: #E0E6EF;
    margin-right: 10px;
    cursor: not-allowed;
    color: #000000;
    text-decoration: none;
}
.prod-card__action--disable:hover {
    color: #23262B;
}
.prod-card__action--download{
    background-color: #26E71E;
    margin-right: 10px;
    color: black;
    text-decoration: none;
}
.prod-card__action--download:hover {
    color: black;
    background-color: #1DB317;


}
.prod-card__action--preview{
    border: 1px solid#42BF64;
    background-color: #FFFFFF;
    text-decoration: none;
    color: #111 !important;
}
.prod-card__action--preview:hover {
    color: #111 !important;
    background-color: #1DB317;
}
.prod-card__more-details-cont{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 90%;
    padding: 15px 0px;
    max-height: 80px;
}
.prod-card__more-details-title{
    color: #23262B;
    font-size: 14px;
    font-weight: 400;
}
.prod-card__more-details-desc{
    margin: 0;
    font-size: 16px;
    color: #23262B;
}
.prod-card__imgcon{
    width: 100%;
    height: 20rem;
    
}

.prod-card__img{
    border-radius: 0px 8px 8px 0px !important;

    @media (max-width:609px){
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        height: 94% !important;
    }

  }

.prod-card__img{
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
.prod-card__content-cont{
    border-right: 1px solid #E3EAF4;
    display: flex;
    flex-direction: column;
}
.prod-card__content--padding{
    padding: 20px 20px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.prod-card__more-details-cont--padding{
    border-top: 1px solid #E3EAF4;
    margin-top: auto;

}

@media (max-width:992px){
    .prod-card__content-cont{
        padding-top: 20px;
        border: 0;
        border-top: 1px solid #E3EAF4;
    }
    .prod-card__content--padding{
        padding: 0px 20px 0;
    }
    .prod-card{
        width: 100%;
    }
    .prod-card__img--padding{
        padding:  20px 20px 20px 20px;
    }
}

@media (max-width:426px){
    .prod-card__img--padding{
        padding: 20px;
    }
    .prod-card{
        width: 90%;
    }
    .prod-card__more-details-cont{
        flex-direction: column;
    }
    .prod-card__more-detail{
        margin-bottom: 10px;
    }
    .prod-card__more-details-title{
        margin: 0;
    }
}

.card-container{
    margin-bottom: 140px;
}

.prod-card-cont {
    @media (max-width:426px){
        padding: 0px 20px ;
    }
}

.card-mobile{
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    background: #F4F8FB;
    border: 1px solid rgba(206, 219, 228, 0.7);
    display: flex;
    flex-direction: column;
    margin: 20px auto;

}
.card-header{
    // height: 33%;
    background-color: #F4F8FB !important;
    border-bottom: solid 1px #C3C9D2 ;
   
    padding: 20px !important;

    // @media (max-width:426px){
    //     padding: 20px;
    // }
}
.mobile-card-header-content{
    width: 100%;
    
}

.mobile-card-header-title{
    display: flex;
    align-items: center;
}

.mobile-card__logo{
    width: 60px !important;

}

.mobile-card__headertext{
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #00122F;
    width: 100%;
    text-align: left;
    padding-top:12px ;
    margin-bottom: 10px !important;
    height: 66px;
}

.mobile-card__buttons{
    padding: 20px 0px;
    padding-bottom: 0;
    display: flex;
    justify-content:space-between;
    width: 100%;
}
.download-free{
    background-color: #26E71E ;
    text-decoration: none;
    width: 60%; 
    height: 40px;
    text-decoration:none;
    border-radius: 8px;
    box-shadow: 0px;
    border-style: none  !important;
    font-size:14px;
    color: black !important;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &.disabled {
        background-color: #E0E6EF;
        font-weight: 500;
    }
}


.download-free:active{
    text-decoration: none;
    color: black !important;

}

.review{
    background-color: transparent;
    border: 1px solid #26E71E;
    width: 40%;
    height: 40px;
    text-decoration:none;
    border-radius: 8px;
    box-shadow: 0px;
    width: 35%;
    font-size:14px;
    color: black !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;
    &:hover { 
        background-color: #26E71E;
        font-weight: bold;
    }

}
.download-free:active{
    text-decoration: none;
    color: black !important;

}

.card__mobile-details{
    padding: 3px 0px;
    &:last-of-type {
        .card__mobile-text {
            margin: 0;
        }
    }
}
.card__mobile-header{
    padding: 0px;
    color: #939CAB;
    font-size: 14px;
    font-weight: 400;
}



.card__mobile-text{
    color: #00122F;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

}

.project-col-skel {
    width: 75.3%;
    margin: 0 auto;
}