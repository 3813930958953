.cv-category:not(:last-of-type){
    margin-bottom: 30px;
}

.cv-categoreis__year{
    color: #181818;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    font-style: italic;
}
.cv-categories__job-title{
    color: #181818;
    font-size: 27px;
    line-height: 27px;
    font-weight: 700;
    margin-bottom: 20px;
}
.cv-categories__job-title:last-child{
    margin-bottom: 0px;
}
.cv-categories__job-place{
    color: #939CAB;
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 25px;
}
.cv-categories__job-place:last-child{
    margin-bottom: 0px;
}
.cv-categories__job-place:last-child{
    margin-bottom: 0px;
}
.cv-categories__job-responsiblity-cont{
    margin-bottom: 40px;
}
.cv-categories__job-responsiblity-cont:last-child{
    margin-bottom: 0px;
}
.cv-categories__job-responsiblity-title ,.cv-categories__job-reference-title{
    color: #181818;
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    margin-bottom: 0px;
}
.cv-categories__job-responsiblity-desc{
    font-size: 18px;
    line-height: 26px;
    color: #181818;
}
.cv-categories__job-responsiblity-list{
    margin: 0;
    list-style: initial;

}
.cv-categories__job-responsiblity-item,.cv-categories__job-reference-item{
    color: #181818;
    font-size: 18px;
    line-height: 26px;
}
.cv-categories__job-reference-list{
    margin: 0;
    list-style: initial;
}
