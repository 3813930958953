.header {
    @media only screen and (max-width: 600px)  {
        .container { 
            padding-top: 10;
        }
        margin-top: 80px !important;
    }
    .header_title {
        font-size: 50px;
        font-weight: 400;
        text-align: center;
        span,a {
            color: #3AAA35 !important;
            text-decoration: underline;
        }
        @media only screen and (max-width: 600px)  {
            font-size: 28px;
            padding:  0 5px;
            line-height: 38px;
            margin-bottom: 0;
        }
    }
    .header_description {
        font-size: 28px;
        font-weight: 400;
        text-align: center;
        line-height: 45px;
        padding:  0 60px;
        display: block;
        color: #23262B;
        span,a {
            color: #3AAA35 !important;
            text-decoration: underline;
        }
        @media only screen and (max-width: 600px)  {
            padding:  0px;
            font-size: 18px;
            line-height: 26px;
            margin-top: 20px;
        }
    }
    .header_desc {
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        text-align: center;
        line-height: 36px;
        display: block;
        color: #23262B;
        margin: 0 auto;
        width: 50%;
        span,a {
            color: #3AAA35 !important;
            text-decoration: underline;
        }
        @media only screen and (max-width: 600px)  {
            width: 100%;
            padding:  0 5px;
            font-size: 26px;
            line-height: 36px;
        }
    }
    .header_image {
        width: 100%;
        overflow: hidden;
        margin: 100px 0;
        >img {
            width: 100%;
            @media only screen and (max-width: 600px)  {
                margin: 40px -50px;
            }
        }
    }
}