.header_one {
  height: 110px;
  border-bottom: 1px solid #eaeaea;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.2s ease-in-out;
  z-index: 55555;
  &.active {
    top: -110px;
  }
  @media only screen and (max-width: 600px) {
    padding: 0 20px;
  }
  .container {
    .row {
      .navbar_one {
        list-style: none;
        display: flex;
        justify-content: space-between;
        padding: 0;
        align-items: center;
        margin: 0;
        .navs {
          white-space: nowrap;
          font-weight: 400;
        }
      }
    }
  }
}

.nav_item_link {
  transition: all 0.2s linear;
  position: relative;
  &::before{
    content: '';
    position: absolute;
    width: 0%;
    height: 2px;
    top: 50%;
    background-color: #3AAA35;
    left: -15%;
    transition: all .3s;
}
  &:hover {
    color: #3aaa35 !important;
    text-decoration: none!important;
    &::before{
      width: 125%;
  }
  }
  &.active {
    color: #3aaa35 !important;
    text-decoration: none!important;
    font-weight: 700;
    &::before{
      content: '';
      position: absolute;
      width: 125%;
      height: 2px;
      top: 50%;
      background-color: #3AAA35;
      left: -15%;
      transition: all .3s;
  }
  }
}
.navs {
  @media only screen and (max-width: 600px) {
    display: none !important;
  }
}

@keyframes ButtonAnimation {
  50% {
    transform: scale(0.8);
    }
  100% {
      transform: scale(1);
  }
}
// export const ColorGreen = '#42BF64'

// const ButtonAnimation = keyframes
// `50% {
//     transform: scale(0.8);
// }
// 100% {
//     transform: scale(1);
// }`

.toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
  float: right;
  @media only screen and (max-width: 600px) {
    margin-left: 20px;
  }
}

.toggle-btn--lang{
  font-size: 14px;
}