.card365_cont {
    padding: 0 !important;
    margin: auto !important;
    width: 90%;
    cursor: pointer;
    @media only screen and (max-width: 600px) {
        padding: 0 20px !important;
        width: 100%;

    }
    
}


.card_365 {
    height: 580px;
    width: 100% !important;
    display: block;
    background-color: #F3F6F9;
    border: 1px solid rgba(206, 219, 228, 0.7);
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 30px 0 0 !important;
    position: relative;
    z-index: 1 !important;
    transition: all .25s ease-in-out;
    &:hover {
    background-color: #FCFEFF;
        .img__arrow {
            opacity: 1;
            overflow: hidden;
        }
    }
    &.skel {
        background-color: #fff;
    }
    &:focus{
    background-color: #E5EBEF;

    }
    @media only screen and (max-width: 600px) {
        padding-top: 10 !important;
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;



    }
}

.card_365_head{
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #23262B;
    display: flex;
    align-items: center;
    cursor: pointer;
    .name {
        transition: all 0.2s linear;
        font-size: 20px;
        line-height: 30px;
        color: #23262B;
        &:hover {
            color: #3AAA35;
        }
    }
}


.price {
    float: right;
}

.card_365_foot {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 70px;
    border-top: 1px solid rgba(219, 226, 237, 1);
    padding: 0 30px 0px 30px;
    width: 100%;
}


.details_link {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        color: #3AAA35 !important;
    }
}

.btn_load {
    background-color: #3AAA35;
    color: #fff;
}

.fav_span {
    cursor: pointer;
}
.fav_span.favourited svg path{
    fill: transparent;
    stroke: black;
}
.fav_span svg path{
    fill :red
}
.img__arrow {
    display: flex;
    opacity: 1;
    transition: all 0.3s linear;
    position: absolute;
    top: 0;
    left: 0%;
    // background-color: #3baa35af;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 20px;
    background-color: transparent;
    border: 0;
    outline: 0;
    &:focus-visible{
        background-color: #E5EBEF;
    }
    
}


@keyframes trans {
    from {
        margin-left: -20px;
    }
    to {
        margin-left: 0;
    }
}

.my-masonry-grid {
    @media only screen and (max-width: 600px) {
        margin: 0 !important;
    }
}


.card_365_card_image_cont {
    display: flex;
    margin: auto !important;
    padding: 0px;
    justify-content: center;
    align-items: center;
    height: 450px;
    width: 100% !important;
    img { 
        object-fit: contain;
    }
    span {
        width: 100%;
    }
}

.load_more{
    border-radius: 60px !important;
    @media only screen and (max-width: 600px) {
        width: 90% !important;
        padding: 40px , 20px
    }
}

.img_style_skel {
    width: 100% !important;
    height: 360px;
    display: block;
}
