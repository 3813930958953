.works__details {
    // img {
    //     display: block;
    //     width: 100%;
    //     height: 90vh;
    //     object-fit: cover;
    // }
    .works_details_header{
        height: 90vh;
        // background: url('https://layouti.com/wp-content/uploads/2021/04/11Free-Paper-Tube-Logo-Mockup-PSD-2019-scaled.jpg') no-repeat fixed;
        background-position: center;
    }
}

@media only screen and (max-width: 426px)  {
    .toggle_mob .toggle-btn{
        display: inline-block !important;
    }
    .toggle-btn{
        display: none !important;
    }
}