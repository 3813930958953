.projects{
    margin-top: 30px;
    @media(max-width:600px){
        padding: 0 20px;
    }
}
.project-col-cont{
    margin-bottom: 60px;
    @media(max-width:600px){
        margin-bottom: 20px;
        padding: 0 20px !important;
    }
}
.projects .project{
    display: flex;
    justify-content:center;
    align-items: center;
    width: 90%;
    position: relative;
    overflow: hidden;
    padding: 0;
    border: 1px solid #ffffff;

    @media(max-width:426px){
        width: 100%;
    }
    &:hover  {
        .works__project__name  {
            bottom: 0;
            
        }
        border: 1px solid #e8e8e8;
    }
}

.project img{
    transition:all 3s ease-in-out !important;
    &:hover{
        transform:scale(1.1)
    }
}
.project-even{
    margin-top:120px!important;
    @media(max-width:769px){
        margin-top:0!important;
    }
}
.projects .project2{
    display: flex;
    justify-content:center;
    align-items: center;
    background-color: rgba(247, 249, 252, 0.714);;
}
.projects .project2 img{
   margin: 0;
}
.projects .works__project__name{
    position:absolute;
    bottom: -100px;
    transition: all 0.25s ease-in-out;
    left: 0;
    height: 100px;
    background-color: #fafafa; 
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    @media(max-width:426px){
        width: 100%;
    }
    @media(max-width:769px){
       margin-bottom: 20px;
    }
}
.projects .works__project__name h6{
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 25px;
    margin: 0;
    color: #000;
    &:first-of-type {
        width: 80%;
    }
    &:last-of-type {
        font-size: 1.3rem;
    }
}
.projects .works__project__name p{
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #23262B;
}
.projects .proj-bottom{
    margin-top: 180px;
}

.project-img-cont{
    width: 100%;
    min-height: 370px;
    overflow: hidden;
    @media(max-width:600px){
        min-height: 0px !important;
    }
}
.project-img-cont--full-height{
    min-height: 540px;
}
.project-img-cont--same-works{
    min-height: 380px;
}
// .project-img-cont::before{
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     width: 100%;
//     height: 100%;
//     background-color: red;
//     transition: all .3s ease-in-out;
// }
// .project-img-cont::after{
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     width: 100%;
//     height: 100%;
//     background-color: green;
//     transition: all .3s ease-in-out;
// }
.project-img-cont--full-height {
    @media(max-width:426px){
        min-height: 350px;
    }
}