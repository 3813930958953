.details__header {
    width: 100%;
    height: 450px;
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media only screen and (max-width: 600px) {
        height: 250px;
    } 
}


.arrow_ctrl{
    padding: 40px 0;
    margin: 50px 0;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
}

.arrow_content {
    display: flex;
    align-items: center;
    gap: 10px;
    &.right {
        float: right;
        p {
            text-align: right;
        }
    }
    span{
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid #000;
        cursor: pointer;
        transition: all 0.3s linear;
        &:hover {
            border-color: #3aaa35;
            color: #3aaa35;
        }
        @media only screen and (max-width: 600px) {
            width: 40px;
            height: 40px;
        } 
    }
    @media only screen and (max-width: 600px) {
        padding: 0 20px;
    }
}

.arrow_content_info {
    p {
        margin: 0;
        padding: 0;
        line-height: 18px;
        color: #000 !important;
        &:first-of-type {
            font-size: 14px;
            font-weight: 600;
            color: #999;
        }
        &:last-of-type {
            font-size: 18px;
            font-weight: 400;
        }
    }
    
}

.similar_apps {
    .similar_apps_title {
        h3 {
            font-size: 20px;
            font-weight: 700;
            margin: 0;
            @media only screen and (max-width: 600px) {
                font-size: 32px;
                line-height: 42px;
            } 
        }
        a {
            color: #3aaa35;
            &:hover {
                color: #3aaa35;
            }
            @media only screen and (max-width: 600px) {
                display: none;
            } 
        }
    }
    @media only screen and (max-width: 600px) {
        padding: 20px;
    } 
}

.info_wrap {
    p {
        margin: 0 !important;
        line-height: 28px;
        font-size: 18px;
    }
}

.mobile-card{
    width: 90%;
    // height: 516px;
    background-color: #fff;
    border: 1px solid #dddddd;
    margin: auto;
    border-radius: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    box-shadow: 0px 10px 40px -5px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 600px) {
        margin-top: 30px;
        box-shadow: 0px 10px 40px -5px rgba(0, 0, 0, 0);

    } 
    .info_card_head{
        padding: 0 30px;
        .info_card_head_date {
            span {
                font-size: 18px;
                padding-left: 5px;
            }
        }
        .info_card_head_title {
            font-size: 24px;
            font-weight: 700;
        }
        .info_card_head_cearor {
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
        }
    }
    .info_card_av {
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 20px 30px;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        margin-top: 30px;
        p {
            font-size: 16px;
            margin: 0;
            color: rgb(97, 97, 97);
        }
    }
    .info_card_desc {
        p {
            font-size: 16px;
            line-height: 22px;
            color: #333;
            padding: 30px;
            padding-bottom: 0;
        }
    }
    .info_card_btns {
        padding-left: 30px;
        padding-right: 30px;
        button, a {
            display: block;
            width: 100%;
            height: 60px;
            border: none;
            background-color: transparent;
            transition: all 0.3s linear;
            border-radius: 12px;
            cursor: pointer;
            &.get_in_touch {
                border: 1px solid #3AAA35;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                cursor: pointer;
                color: #000;
                &:hover {
                    
                    color: #fff !important;
                    background-color: #3AAA35;
                }
            }
            &.download {
                text-decoration: none;
                background: #E4EBF3;
                color: #000 !important;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                // &:hover {
                //     color: #000;
                // }
            }
        }
    }
}