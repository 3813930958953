.contact-us__get-in-touch {
  margin: 100px 100px 170px;
}

.contact-us__get-in-touch-heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 20px;
}
.contact-us__get-in-touch-second-heading {
  font-size: 25px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 20px;
}
.contact-us__get-in-touch-content {
  font-size: 25px;
  line-height: 35px;
  color: var(--main-text-color);
}
.contact-us__get-in-touch-mail {
  font-weight: bold;
  text-decoration: underline;
  color: var(--main-text-color);
}
.contact-us__get-in-touch-desc {
  font-size: 20px;
  color: #666666;
  width: 60%;
  margin-bottom: 10px;
  line-height: 26px;
}
.contact-us__deck-content {
  font-size: 20px;
  color: #666666;
  width: 60%;
  line-height: 30px;
}

@media (max-width: 992px) {
  .contact-us__get-in-touch-heading {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .contact-us__get-in-touch-cont {
  }
}
@media (max-width: 769px) {
  .contact-us__get-in-touch-desc {
    width: 100%;
  }
  .contact-us__get-in-touch {
    margin: 100px 50px 140px;
  }

  .contact-us__deck-content {
    font-size: 20px;
    color: #666666;
    width: 100%;
    line-height: 30px;
  }
}
@media (max-width: 426px) {
  .contact-us__get-in-touch-content {
    font-size: 20px;
  }
  .contact-us__get-in-touch {
    margin: 100px 30px;
  }
}

@media (max-width: 376px) {
  .contact-us__get-in-touch {
    margin: 0px 8px;
  }
  .contact-us__get-in-touch-heading {
    font-size: 35px;
    margin-bottom: 0px;
    text-align: left;
    line-height: 45px;
  }
  .contact-us__get-in-touch-second-heading {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    font-size: 20px;
  }
  .contact-us__get-in-touch-cont .getInTouch {
    display: none !important;
  }
  .contact-us__get-in-touch-desc {
    font-size: 16px;
    line-height: 15px;
    color: #000;
    padding-bottom: 0px !important;
  }
}
