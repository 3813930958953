.contact-us__FAQ {
  margin-bottom: 70px;
}
.contact-us__FAQ-heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
}
.contact-us__FAQ-content {
  font-size: 20px;
  line-height: 35px;
  color: #23262b;
}
@media (max-width: 992px) {
  .contact-us__FAQ-heading {
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
}
@media (max-width: 426px) {
  .contact-us__FAQ-content {
    font-size: 20px;
  }
}

@media (max-width: 376px) {
  .contact-us__FAQ {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 40px;
  }
  .contact-us__FAQ-heading {
    padding-top: 80px;
    padding-bottom: 0px;
    text-align: left;
    font-size: 35px;
    margin-bottom: 0px;
  }
  .contact-us__FAQ-content {
    display: none;
  }
}
