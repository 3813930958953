.category-item{
    border-radius: 15px;
    overflow: hidden;
    border: .71px solid rgba(206, 219, 228, 0.7);
    padding: 0;
    position: relative;
}
.category-item__link{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.category__slider-wrapper{
    // margin-bottom: 130px;
}
.category-item__img{
    width: 100%;
    height: 250px;
    object-fit: cover;
}
.category-item__content-wrapper{
    padding: 20px 27px;
    background-color: #F4F8FB;
}
.category-item__content-title{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}
.category-item__content-description{
    margin: 0;
}