.mobile_apps { 
    margin-bottom: 100px;
    margin-top: 140px;

    .mobile_apps_title {
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
    }
    .mobile_apps_desc {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 50px;
        // width: 65%;
    }
    .mobile_apps_img {
        width: 100%;
        margin-bottom: 20px;
    }
    @media (max-width: 600px) {
        margin-bottom: 50px;
        margin-top: 0px;
        padding: 0 20px;
        .col-md-5  {
            padding: 0;
        }
        .col-md-7  {
            padding: 0;
        }
        .col-md-12  {
            padding: 0;
        }
        .col-md-6  {
            padding: 0;
        }
    }
}