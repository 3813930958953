.layouti__skills {
    padding: 70px 0 100px;
    @media only screen and (max-width: 600px) { 
        padding: 50px 0 40px;
    }
    .container {
        .layouti_skills_head {
            display: flex;

            >h1 {
                width: auto;
                font-size: 50px;
                font-weight: 700;
                padding-right: 100px;   
                line-height: 60px;
                width: 100%;
                margin: 0;
            }
            >div { 
                width: 75%;
                >p{
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 30px;
                    padding: 0px;
                    margin: 0px;
                    width: 100%;
                }
            }


            @media only screen and (max-width: 600px) { 
                flex-wrap: wrap;
                padding: 20px;
                padding-top: 0;
                >h1 {
                    width: 95%;
                    font-size: 32px;
                    font-weight: 700;
                    padding-right: 0px;
                    line-height: 42px;

                }
                >div { 
                    >p{
                        display: none;
                    }
                }
            }

        }
    }
    .skills_card_wraper {
        display: flex;
        flex-wrap:wrap;
        margin: 50px 0px 20px;
        transition: all .3s linear;
        @media only screen and (max-width: 600px) { 
            margin: 20px;
        }
        .skills__card {
            flex-basis: calc(100% /3);
            transition: all .5s ease;
            padding: 100px 0;
            padding-left: 50px;
            position: relative;
            overflow: hidden;
            
            .skills__back {
                background-image: url('https://layouti.com/wp-content/uploads/2021/04/2s.png') !important; 
                background: no-repeat center;
                background-size: cover;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: transform 2s ease;
                // transition: opacity 0.1s ease;
                z-index: -1;
                opacity: .7;
            }
            .skills_card_info {
                >h1 {
                    font-size: 45px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    &:first-of-type {
                        color: #DFE5EE;
                        font-size: 45px;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                    &:last-of-type {
                        margin-bottom: 50px;
                    }
                }
                >button {
                    background: transparent ;
                    border: 1px solid #42BF64;
                    color: #42BF64 ;
                    border-radius: 30px;
                    transition: all .3s ease-in-out;
                    width: 240px;
                    height: 50px;
                   
                    &:hover{
                        animation: ButtonAnimation 0.3s linear 1;
                    }
                }
                >p {
                    font-size: 18px;
                    line-height: 28px;
                    color: #fff;
                    margin-top: 40px;
                    transition: all .3s ease;
                    width: 400px;
                }
            }
            &:hover {
                flex-basis: 40%;
                background-color: rgba($color: #42BF64, $alpha: 0.5);
                &:nth-of-type(2){
                    background-color: rgba($color: #000, $alpha: 0.4);
                }
                &:last-of-type{
                    background-color: rgba($color: #cf2e2e, $alpha: 0.4);
                }
              
                .skills_card_info { 
                    >h1 {
                        color: #fff ;
                        
                    }
                    >button {
                        border: 1px solid #fff;
                        color: #fff ;
                        @media only screen and (max-width: 600px) { 
                           display: none;
                        }
                    }
                    
                }
                .skills__back {
                    opacity: 1;
                    transform: scale(1.5);
                    transform-origin: center;
                }
            }
        }
        @media only screen and (max-width: 600px) { 
            flex-wrap: wrap;
            flex-direction: column;
            .skills__card {
                flex-basis: 100%;
                color: #fff;
                margin: 0;
                padding: 0;
                // margin: 0px 20px 20px 20px;
                // padding: 50px 30px;
                .skills__back {
                    opacity: 1;
                }
                .skills_card_info {
                    >h1 {
                        font-size: 45px;
                        font-weight: 600;
                        margin-bottom: 10px;
                        &:first-of-type {
                            color: #DFE5EE;
                            font-size: 45px;
                            font-weight: 600;
                            margin-bottom: 10px;
                        }
                        &:last-of-type {
                            margin-bottom: 30px;
                        }
                    }
                    >button {
                        background: transparent ;
                        border: 1px solid #42BF64;
                        color: #42BF64 ;
                        width: 240px;
                        height: 50px;
                        border-radius: 30px;
                        display: none;
                        transition: all .3s ease-in-out;
                        @media only screen and (max-width: 600px) { 
                            display: none;
                        }
                        &:hover{
                            animation: ButtonAnimation 0.3s linear 1;
                            
                        }
                    }
                    >p {
                        font-size: 18px;
                        line-height: 28px;
                        color: #fff;
                        margin-top: 30px;
                        transition: all .3s ease;
                        width: 100%;
                    }
                }
                &:hover {
                    flex-basis: 100%;
                }
            }
        }
    }
}

@keyframes ButtonAnimation {
  50% {
    transform: scale(0.8);
    }
  100% {
      transform: scale(1);
  }
}

.skills_card_new {
    width: calc(100%/3);
    padding-right: 50px;
    padding-bottom: 50px;
    h1 {
        margin-bottom: 20px;
        width: 100%;
    }
    p {
        font-size: 20px;
        width: 100%;
    }
    @media (max-width: 600px) {
        padding-bottom: 20px !important;
        width: 100%;
        padding: 0;
    }
}