.scroll_top {
    position: fixed;
    bottom: 60px;
    right: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    background-color: var(--main-color);
    color: #000000;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    z-index: 99999;
    transition: all 0.3s linear;
    &:hover { 
    background-color: #267122;

    }
    @media only screen and (max-width: 600px)  {
        bottom: 20px;
        right:  20px;
    }
}