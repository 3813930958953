.header-bread-crumb{
    margin-bottom: 80px;
}
.header-resourses_bread-crumb-link{
    font-size: 55px;
    text-decoration: underline;
    text-underline-offset:10px;
    // border-bottom:2px solid black ;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 0;
    &:hover{
        color: black;
    }
}
.header-resourses_bread-crumb-page{
    font-size: 55px;
    color: Grey;
    font-weight: 700;
    margin-left: 4px;
}
.header-resourses__description{
    font-size: 24px;
    font-weight: 400;
    width: 50%;
    margin-top: 20px;
    margin-bottom: 0px;
}
@media (max-width:1024px) {
    .header-resourses__description{
        width: 60%;
    }
    .header-resourses_bread-crumb-link,.header-resourses_bread-crumb-page{
        font-size: 45px;
    }

}
@media (max-width:768px) {
    .header-resourses__description{
        width: 80%;
        
    }
    .header-resourses_bread-crumb-link,.header-resourses_bread-crumb-page{
        font-size: 35px;
    }
}
@media (max-width:426px) {
    .header-resourses__description{
        width: 100%;
        font-size: 20px;

    }
}