.project_steps {
    margin: 120px 0;
    margin-bottom: 70px;
}


.Deliverables_steps {
    @media (max-width: 600px) {
        padding: 0px 20px !important;
    }
}