.header_layouti {
    height: 110px;
    border-bottom: 1px solid transparent;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.2s ease-in-out;
    z-index: 4000;
    &.active {
        top: -110px;
    }
    &.white {
        background-color: #fff;
        border-bottom: 1px solid #EAEAEA;
        @media only screen and (max-width: 375px)  {
            // background-color: #23262b;
            // .toggle-white-icon path{
            //     fill:white
            // }
        }
    }
    @media only screen and (max-width: 600px)  {
        padding: 0 20px;
    }
    @media only screen and (max-width: 375px)  {
        // background-color: #23262b;
    }
    .container {
        .row{
            .navbar_one {
                list-style: none;
                display: flex;
                justify-content: space-between;
                padding: 0;
                align-items: center;
                margin: 0;
                .navs {
                    white-space: nowrap;
                    font-weight: 400;
                }
            }
        }
    }
    
}


.nav_item_link{
    transition: all 0.2s linear;
    font-size: 14px;
    position: relative;
    &::before{
        content: '';
        position: absolute;
        width: 0%;
        height: 2px;
        top: 50%;
        background-color: #3AAA35;
        left: -15%;
        transition: all .3s;
    }
    &.white__link {
        color: #fff
    }
    &:hover {
        color: #3AAA35 !important;
        text-decoration: none!important;
        &::before{
            width: 125%;
        }
    }
    &.active {
        color: #3AAA35 !important;
        text-decoration: none!important;
        font-weight: 700;
        &::before{
            content: '';
            position: absolute;
            width: 150%;
            height: 2px;
            top: 50%;
            background-color: #3AAA35;
            left: -25%;
        }
    }
}
.navs {
    @media only screen and (max-width: 600px)  {
        display: none !important;
    }
}

@keyframes ButtonAnimation {
  50% {
    transform: scale(0.8);
    }
  100% {
      transform: scale(1);
  }
}
.toggle-btn {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
    float: right;
    @media only screen and (max-width: 600px)  {
        margin-left: 20px;
    }
}
.toggle-btn--lang{
    font-size: 14px;
}