.contact-us__accordion{
    margin-bottom:100px
}
.contact-us__accordion-buttons-cont{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

    
.menu_mobile select { 
    width: 100%;
    display: block;
    height: 55px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 7px 20px;
    background-color: transparent;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    border: 1px solid #26e71e;
    border-radius: 10px;
    outline: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none'  stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
    background-repeat: no-repeat,repeat;
    background-position: right 18px top 50%,0 0;
    background-size: 1em auto,100%;
    margin-bottom: 20px;
}

@media(min-width:600px){
    .menu_mobile{
        
        display: none;
   
    }
}

@media  (max-width: 600px) {
    .contact-us__accordion-buttons-cont{
        display: none;
    }
}
.contact-us__accordion-button{
    margin: 0 20px 15px 0;
    background-color: white;
    border: 1px solid var(--main-color);
    padding: 17px 35px;
    border-radius: 40px;
    color: var(--main-text-color);
    cursor: pointer;
}
.contact-us__accordion-button--active{
    background-color: var(--main-color);
    color:rgb(0, 0, 0);
    font-weight: bold;
}

.contact-us__accordion-title{
    font-size: 28px;
    color:#23262B;
    font-weight: bold;
    text-align: start;
}
.contact-us__accordion-icon-cont{
    min-width: 60px;
    min-height: 60px;
    background-color: #D9F2E0;
    margin-inline-start: auto;
    margin-left: auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact-us__accordion-minus-icon{
    position: absolute;
    width: 20px;
    transition: var(--transition);
    color: var(--main-color);
}
.contact-us__accordion-header button[aria-expanded="false"] .contact-us__accordion-minus-icon:first-of-type{
    transform: rotate(90deg);
}
.contact-us__accordion-body{
    font-size:18px;
    color:#23262B
}
.contact-us__accordion-header {
    margin-bottom: 30px;
}
.contact-us__accordion-header button{
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    outline: 0;
}
.contact-us__accordion-item{
    padding: 40px 20px;
    border-top: 1px solid var(--secondary-color);
}
.contact-us__accordion-item:last-of-type{
    border-bottom: 1px solid var(--secondary-color);
}




@media (max-width:769px) {
    .contact-us__accordion-buttons-cont{
        display: none;
        grid-template-columns: 1fr 1fr;
    }
    
}





@media (max-width:426px) {
    .contact-us__accordion-icon-cont{
        min-width: 50px;
        min-height: 50px;
    }
    .contact-us__accordion-title{
        font-size:24px;
    }
    .contact-us__accordion-body{
        font-size:16px;

    }
}

@media (max-width: 376px){
    .contact-us__accordion{
        padding-left: 8px;
        padding-right: 8px;
    }
}