.about-features__card{
    margin-bottom: 20px;
}
.about-features__card-icon{
    margin-bottom: 25px;
    width: 70px;
    height: 70px;
    @media(max-width:426px){
        display: flex;
        margin :0 auto 25px;
    }
}
.about-features__card-title{
    font-size:32px;
    line-height:36px;
    color:#23262B;
    margin-bottom: 25px;
    font-weight: 600;
    width: 90%;
    @media(max-width:426px){
        width: 100%;
        font-size:36px;
        line-height: 45px;
    }
}
.about-features__card-desc{
    font-size:18px;
    line-height:26px;
    color:#23262B;
    width: 90%;
    width: 90%;
    @media(max-width:426px){
        width: 100%;
    }
}