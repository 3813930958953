.about-features__project-img{
    height: 60px;
    margin-bottom: 20px;
    width: 60%;
    object-fit: contain;
    object-position: left;
}

.about-features__project-desc{
    color: #23262B;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 10px;
}
.about-features__project-cat{
    color: #3AAA35;
    font-weight: 700;
    margin-right:40px;
}
.about-features__project-cat-name{
    color:#b0abab
}