.layouti_product {
    // margin: 100px 0;
    // background-color: white;
    opacity: 1;
    padding-bottom: 0px;
    transition: all .5s linear;
    @media only screen and (max-width: 600px) { 
        padding: 20px;
    }
    .layouti_product_items_wrap{
        transition: all .5s linear;
        transform: translateY(0);
    }
    > *{
        transition: all .5s linear;
    }

    
    .layouti_product_head {
            margin-bottom: 60px;
                display: flex;
                >h1 {
                    width: 50%;
                    font-size: 45px;
                    font-weight: 700;
                    padding-right: 160px;   
                    
                }
                >p{
                    width: 50%;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 30px;
                    margin-left: 20px;
                }
                @media only screen and (max-width: 600px) { 
                    flex-wrap: wrap;
                    margin-bottom: 20px;
                
                    >h1 {
                        width: 95%;
                        font-size: 35px;
                        font-weight: 700;
                        padding-right: 0px;
                    }
                    >p{
                        display: none;
                    }
                }
                
    }
    .layouti_product_items_wrap {
        gap: 100px;
        button {
            font-size: 15px;
            font-weight: 600;
            border-radius: 30px;
            background-color: #42bf64;
            color: #fff;
            border: none;
            transition: all .3s ease-in-out;
            width: 240px;
            height: 50px;
            &:hover{
                animation: ButtonAnimation 0.3s linear 1;
            }
            @media only screen and (max-width: 600px) {
                width: 100%;
            }
        }
        .layouti_product_items {
            display: flex;
            justify-content: space-between;
            margin: 0;
            flex-wrap: wrap;
            &:first-of-type {
             
                
            }
            .layouti_prod_card {
                margin-bottom: 60px;
                flex-basis: 48%;
                @media only screen and (max-width: 600px) { 
                    flex-basis: 100%;
                }
                .layouti_prod_card_img {
                    overflow: hidden;
                    min-height: 550px;
                    @media only screen and (max-width: 600px) { 
                        min-height: unset;
                    }
                    img {
                        transition: all .25s ease;
                        // width: 100%;
                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }
                >h2 {
                    font-weight: 700;
                    line-height: 30px;
                    font-size: 20px;
                    margin-top:20px;
                }
                >p {
                    font-weight: 400;
                }
            }
        }
        @media only screen and (max-width: 600px) {
            flex-wrap: wrap; 
            gap: 0;
            .layouti_product_items {
                flex-basis: 100%;
                
                &:first-of-type {
                    margin-top: 0px;
                    button {
                        display: none;
                    }
                }
            }
        }
    }
}

@keyframes ButtonAnimation {
    50% {
      transform: scale(0.8);
      }
    100% {
        transform: scale(1);
    }
  }