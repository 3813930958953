.term_condition {
    padding-top: 180px;
    .container {
        @media (max-width: 600px) {
            padding: 0 20px;
        }
    }
    .terms_list {
        list-style: square;
        padding: 0;
        top: 180px !important;
        li {
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 37px;
            text-decoration-line: underline;
            color: #00122F;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
                color: #12BD0B;
            }
            &.active {
                color: #12BD0B;
            }
        }
    }
    h1 {
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 30px;
        @media (max-width: 600px) {
            font-size: 32px;
            line-height: 42px;
        }
    }
    a {
        color: #12BD0B !important;
        text-decoration: none;
    }
    ul {
        list-style:square;
    }
    p { 
        margin-bottom: 8px;
    }
}