.popup{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    transition: all .15s linear;
    opacity: 0;
    z-index:-1 ;
    background: rgba(0,0,0,.7);
    overflow-y: auto;
}
.popup-active{
    opacity: 1;
    z-index: 100000;
}
.popup-background-color{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(0,0,0,.7); */
    z-index: 1;
}
.popup-container{
    position: absolute;
    top: 60px;
    left: 50%;
    width: 430px;
    max-height:100% ;
    margin:auto;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    padding: 30px 0 50px;
    transform: translateX(-50%) scale(0);
    transition: all .2s linear;
    z-index: 2;
}
.popup-active .popup-container{
    /* transform:translateX(-50%) scale(1); */
    animation: openPopUpAnimation .2s linear forwards ;
}
.popup-header{

}
.popup-header__title{
    font-size: 32px;
    font-weight: 700;
    color: #42BF64;
    /* margin-bottom: 20px; */
    width: 70%;
    margin: 0 auto 37px;

}
.popup-header__desc{
    font-size: 20px;
    font-weight: 400;
    color: #23262B;
    /* margin-bottom: 37px; */
    width: 70%;
    margin: 0 auto 37px;

}
.popup-header__error{
    font-size: 30px;
    font-weight: 400;
    color: #23262B;
    /* margin-bottom: 37px; */
    width: 70%;
    margin: 0 auto 37px;
}
.popup-image__container{

}
.popup-image__icon{
    margin-bottom: 40px;
}
.popup-footer__close-container{

}
.popup-footer__close-button{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border:1px solid Green;
    width: 232px;
    height: 49px;
    font-size: 18px;
    background-color: white;
    margin: auto;
}
@keyframes openPopUpAnimation {
    0%{
        transform:translateX(-50%) scale(0)
    }
    50%{
        transform:translateX(-50%) scale(1.1)
    }
    100%{
        transform:translateX(-50%) scale(1)
    }
}
@media (max-width:426px) {
    .popup-container{
        width: 350px;
        top: 30px;
        /* bottom: 10px; */
    }
    .popup-image__icon{
        margin-bottom:20px;
    }
    .popup-header__title{
        margin: 0 auto 17px;
    }
}