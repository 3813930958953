.tabs_cont {
    @media only screen and (max-width: 600px)  {
        padding: 0px 20px !important;
    }
}


.content_card_cont {
    @media only screen and (max-width: 600px)  {
        padding: 0px 20px !important;
    }
}

.content_card {
    width: 100%;
    background-color: #F4F8FB;
    border: 1px solid rgba(206, 219, 228, 0.7);
    border-radius: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    .cover_img {
        width: 100%;
        height: 317px;
        object-fit: cover;
        display: block;
        border-radius: 20px 20px 0 0 ;
        cursor: copy;
    }
    .content_card_body {
        margin-top: 20px;
        margin: 20px;
        .desc {
            font-size: 18px;
            line-height: 28px;
            width: 100%;
            margin: 0;
            color: #000;
            cursor: copy;
        }
        .desc_title {
            font-size: 24px;
            line-height: 34px;
            width: 80%;
            margin: 0;
            color: #000;
            font-weight: 700;
            cursor: copy;
        }
        .long_desc {
            font-size: 18px;
            line-height: 28px;
            width: 100%;
            margin-top: 20px;
            color: #23262B;
            cursor: copy;
        }
    }
}

.nav-link {
    border-radius: 50px !important;
    height: 57px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: #F4F8FB !important;
    color: #000 !important;
    border: 1px solid #CEDBE4 !important;
    &.active {
        color: #fff !important;
        background-color: #3AAA35 !important;
        border: 1px solid #3AAA35 !important;

    }
}
.nav_tap_to {
    width: calc(100% / 2);
    @media only screen and (max-width: 600px)  {
        width: 100%;
        
    }
}



.load_more {
    display: block;
    margin: 20px auto;
    width: 280px;
    height: 45px;
    border-radius: 10px;
    border: 1px solid #3AAA35;
    color: #23262B;
    font-size: 16px;
    background-color: transparent;
}
.load_more:hover {
    border:none;
    color: #fff;
    background-color: #349B2F;
}
.notload-h2{
    text-align: center;
    font-size: 16px;
    color: #23262B;
}
.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: auto;
    gap: 20px;
}



.date {
    cursor: copy;
}