.userInterface-content {
  padding-bottom: 50px;
}
.userInterface-content .row1 {
  min-height: 450px;
  display: flex;
  position: relative;
  padding: 100px 0;
}
.userInterface-content .row1 .text {
  width: 97%;
  margin-left: 10px;
}
.userInterface-content .row1 .text h2 {
  color: #23262B;
  font-family: "Cairo", Sans-serif;
  font-weight: 600;
  font-size: 48px;
  line-height: 54px;
}
.userInterface-content .row1 img {
  height: 100%;
}
.userInterface-content .row1 .text p {
  margin-bottom: 20px;
  margin-top: 20px;
  font-family: "Cairo", Sans-serif;
  font-weight: 400;
  line-height: 28px;
  color: #23262B;
  font-size: 18px;
}
.userInterface-content .row1 .text ul {
  display: contents;
}
.userInterface-content .row1 .text ul li {
  margin-bottom: 10px;
}
.userInterface-content .row1 .text ul .check-icon {
  background-color: #39b14a;
  color: #fff;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  padding: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: 12px;
}
.userInterface-content .row1 .text ul .prag {
  font-weight: 400;
  font-family: "Cairo", Sans-serif;
  color: #23262B;
  font-size: 18px;
}
.userInterface-content .row2 {
  min-height: 360px;
  display: flex;
  position: relative;
  padding: 100px 0;
}
.userInterface-content .row2 img {
  height: 100%;
}
.userInterface-content .row2 .text h2 {
  color: #23262B;
  font-family: "Cairo", Sans-serif;
  font-weight: 600;
  font-size: 48px;
  line-height: 54px;
  margin-bottom: 20px;
}
.userInterface-content .row2 .text p {
  margin-bottom: 20px;
  margin-top: 20px;
  font-family: "Cairo", Sans-serif;
  font-weight: 400;
  line-height: 28px;
  color: #23262B;
  font-size: 18px;
}
.userInterface-content .row2 .text ul {
  display: contents;
}
.userInterface-content .row2 .text ul li {
  margin-bottom: 10px;
}
.userInterface-content .row2 .text ul .check-icon {
  background-color: #39b14a;
  color: #fff;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  padding: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.userInterface-content .row2 .text ul .prag {
  font-weight: 400;
  font-family: "Cairo", Sans-serif;
  color: #23262B;
  font-size: 18px;
}
.userInterface-content .image-side{
  border-radius: 30px;
}
@media only screen and (max-width: 991px) {
  .userInterface-content .row1{
    margin-top: 500px;
  }
}
@media only screen and (max-width: 768px) {
  .userInterface-content {
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }
  .userInterface-content .row1{
    margin-top: 100px;
  }
}
@media only screen and (max-width: 528px) {
  .userInterface-content {
    margin: 0;
    padding: 10px;
  }
  .userInterface-content .text h2 {
    font-size: 32px !important;
    line-height: 42px !important;
  }
  .userInterface-content .text ul .prag {
    font-size: 21px !important;
    line-height: 31px !important;
  }
  .userInterface-content .row1 {
    margin-bottom: 40px !important;
  }
  .userInterface-content .row2 .text ul .check-icon {
    width: 15px !important;
    height: 15px !important;
    margin-right: 15px;
  }
}

  @media only screen and (max-width: 321px) {
    .userInterface-content .row1{
      margin-top: 250px;
    }
  }

