.export_screen {
  
    padding: 150px 0;
    padding-top: 50px;
    padding-bottom: 80px;
    .export_screen_cont {
        img  {
            width: 400px;
            margin-right: 50px;

        }
        h2  {
            font-weight: 700;
            font-size: 30px;
            line-height:36px;
        }
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #23262B;
    
        }

    }
    @media (max-width: 600px) {
            padding-bottom: 0px;
        .export_screen_cont {
            padding: 0 20px;
            img { 
                margin: 0;
                width: 90%;
                margin-bottom: 20px;
            }
            .col { 
                padding: 0;
            }

        }
    }
}

.export_screen_img {
    margin-bottom: 50px;
    position: relative;
    margin-top: 130px;
    img  {
        width: 100%;
    }
    &:after { 
        content: '';
        position: absolute;
        top: 300px;
        left: 0;
        width: 100%;
        height: 1345px;
        background-color: #F6F6F6;
        z-index: -1;
    }
    @media (max-width: 600px) { 
        &:after { 
            top: 100px;
            height: 400px;
            
        }
    }
}