.alert_message {
    position: fixed;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.548);
    color: #fff;
    width: 200px;
    height: 45px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s linear;
    &.active {
        opacity: 1;
    }
}