.challenges {
    background-color: #f6f6f6;
    padding: 100px 0;
    padding-bottom: 150px;
    @media (max-width: 600px) {
        padding-top: 50px;
    }
}
.sections_info {
    padding: 100px 0;
    padding-bottom: 20px;
    padding-top: 0;
    margin-top: 100px;
    h2 { 
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 20px;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
    }
    @media (max-width: 600px) {
        padding: 50px 20px;
        margin-top: 10px;
        p  {
            margin-bottom: 20px;
        }
    }
}