.header {
    .header-resourses_title {
        font-size: 55px;
        font-weight: 700;
        text-align: start;
        width: 50%;
        line-height: 63px;
        margin-top: 60px;
        margin-bottom: 20px;
        span {
            color: #3AAA35;
            text-decoration: underline;
        }
        @media only screen and (max-width: 600px)  {
            font-size: 46px;
            // padding:  0 5px;
            line-height: 55px;
        }
    }
    .header-resourses_description {
        font-size: 24px;
        font-weight: 400;
        text-align: start;
        line-height: 36px;
        display: block;
        color: #23262B;
        width: 50%;
        @media only screen and (max-width: 600px)  {
            font-size: 20px;
            line-height: 30px;
            margin-top: 20px;
        }
    }
    .header_desc {
        font-size: 30px;
        font-weight: 400;
        text-align: center;
        line-height: 45px;
        display: block;
        color: #23262B;
        margin: 0 auto;
        width: 50%;
        @media only screen and (max-width: 600px)  {
            width: 100%;
            padding:  0 5px;
            font-size: 26px;
            line-height: 36px;
        }
    }
    .header_image {
        width: 100%;
        overflow: hidden;
        margin: 100px 0;
        >img {
            width: 100%;
        }
        @media only screen and (max-width: 600px)  {
            margin: 40px 0;
        }
    }
    
    @media (max-width:1024px) {
        
            padding: 0 80px;
        
        .header-resourses_title{
            width: 60%;
        }
        .header-resourses_description{
            width: 60%;
        }
        
    }
    @media (max-width:768px) {
        .header-resourses_title{
            width: 80%;
        }
        .header-resourses_description{
            width: 80%;
        }
    }
    @media (max-width:426px) {
        padding: 0 20px;
        .header-resourses_title{
            width: 100%;
        }
        .header-resourses_description{
            width: 100%;
        }
    }
}

