.layouti_product_items_wrap {
    gap: 100px;
    button {
        font-size: 15px;
        font-weight: 600;
        border-radius: 30px;
        background-color: var(--main-color);
        color: #000000;
        font-weight: bold;
        border: none;
        transition: all .3s ease-in-out;
        width: 240px;
        height: 50px;
        &:hover{
            animation: ButtonAnimation 0.3s linear 1;
        }
        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }
}