.category-page-item{
    margin-bottom: 50px;
}

.load_more {
    display: block ;
    margin: 20px auto;
    width: 280px ;
    height: 45px ;
    border-radius: 10px;
    border: 1px solid #3AAA35;
    color: #23262B;
    font-size: 16px;
    background-color: transparent;
}
.load_more:hover {
    border:none;
    color: #fff;
    background-color: #349B2F;
}
.notload-h2{
    text-align: center;
    font-size: 16px;
    color: #23262B;
}
.spin {
    text-align: center;
    margin-top: 20px;
}