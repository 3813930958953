.headerContent{
    position: relative;
    width: 100%;
    min-height: 620px;
    background-color: #23262B;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
}
.headerContent .home-text{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.headerContent .home-text h2{
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #fff;
    width: 60%;
}
.headerContent .home-text .see-projects{
    width: 200px;
    height: 48px;
    background: #42BF64;
    border-radius: 34.7209px;
    border: 1px solid #42BF64;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    margin-top: 30px;
    animation: ButtonAnimation 0.3s linear 1;
}
@keyframes ButtonAnimation {
  50% {
    transform: scale(0.8);
    }
  100% {
      transform: scale(1);
  }
}