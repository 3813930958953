.about_text { 
    padding: 70px 0 50px;
    h2 { 
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
        width: 50%;
        padding-right: 110px;
    }
    p {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: #23262b;
        width: 49%;

    }
    @media (max-width: 600px ) {
        padding: 40px 10px 30px 0px;
        .row { 
            padding: 0;

            .col-md-6 { 
                padding: 0;
            }
        }
        h2 { 
            font-size: 36px;
            line-height: 45px;
            width: 100%;
            padding-right: 0px;

        }
        p { 
            font-size: 18px;
            line-height: 26px;
            width: 100%;
        }
        .about-text--margin {
            margin: 0;
        }
    }
}