.body_branding { 
    margin: 150px 0;
    h2 { 
        font-weight: 600;
        font-size: 45px;
        line-height: 55px;
        width: 50%;
        margin-left: 80px;
    }
    p { 
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        width: 90%;
        margin-bottom: 20px;
    }
}

.branding {
    margin-bottom: 150px;
}