.buildTimesless{
    margin: 70px 0 50px 0;
    .container {
        @media(max-width:600px){
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    @media(max-width:768px){
        margin-top: 50px;
    }
}
.buildTimesless .text-build {
    margin-bottom: 90px;
    @media(max-width:768px){
        margin-bottom: 50px;
    }
}
.buildTimesless .text-build h2{
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    color: #23262B;
    width: 90%;
    @media(max-width:1024px){
        width: 90%;
    }
    @media(max-width:600px){
        font-size: 35px;
        line-height: 45px;
    }
}
.buildTimesless .text-build p{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #23262B;
    width: 80%;
    @media(max-width:1024px){
        width: 90%;
    }
    @media(max-width:600px){
        display: none;
    }
}
.buildTimesless .menu{
    @media(max-width:600px){
        display: none;
    }
}
.buildTimesless .menu_mobile{
    select { 
        width: 100%;
        display: block;
        height: 55px;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 7px 20px;
        background-color: transparent;
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        border: 1px solid #26e71e;
        border-radius: 10px;
        outline: none;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        -ms-appearance: none;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none'  stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
        background-repeat: no-repeat,repeat;
        background-position: right 18px top 50%,0 0;
        background-size: 1em auto,100%;
    }
    @media(min-width:600px){
        display: none;
    }
}
.buildTimesless .menu ul{
    list-style: none;
    display: inline-flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    width: 100%;
    gap: 20px;
}
.buildTimesless .menu ul li{
    // margin-right: 20px;
    margin-bottom: 10px;
    border: 1px solid #26e71e;
    box-sizing: border-box;
    border-radius: 50px;
    // font-weight: 600;
    // width: calc(100%/6.9);
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #23262B;
    padding: 10px 28px;
    cursor: pointer;
    @media(max-width:768px){
        margin-right: 10px;
    }
}
.buildTimesless .menu ul li.active{
    background: #26e71e;
    color: #000000;
    font-weight: bold;
}