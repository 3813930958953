.about-section{
    transition: all .25s linear;
    background-color: white;
    margin-top: 80px;
    > *{
        transition: all .25s linear;
    }
    @media (max-width: 600px) {
        padding: 20px;
        .container  {
            padding: 0;
        }
    }
}