.persona { 
    margin-bottom: 100px;
    margin-top: 100px;
    .persona_title {
        font-size: 35px;
        font-weight: 700;
        line-height: 47px;
        letter-spacing: 0em;
        text-align: left;
    }
    .persona_img {
        width: 100%;
        margin-top: 20px;
    }
    .persona_info {
        margin: 50px 0 ;
        h2 { 
            font-weight: 700;
            font-size: 30px;
            line-height: 36px;
        }
        p { 
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 0px;
        }
        &:first-of-type  {
            p { 
                // width: 65%;
            }
        }
    }
}