.thanks_for_watch {
    background-color: #F8F8F8;
    padding: 100px 0;
    margin-bottom:  100px;
    border-top: 1px solid #e5e5e5;
    .container {
        .row {
            .col {
                h2 {
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 46px;
                    text-transform: uppercase;
                }
                p {
                    height: 90px;
                    width: 491px;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 26px;
                }
                button {
                    height: 45px;
                    width: 179px;
                    border-radius: 34.720863342285156px;
                    background-color: #00BB9C;
                    color: #fff;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    font-size: 16px;
                    font-weight: 500 !important;
                    margin-top: 50px;
                }
            }
        }
    }

    @media (max-width: 600px) {
        padding-right:20px;
        padding-left: 20px;
        .container {
            .row {
                .col {
                    padding: 0;
                    p {
                        width: 100%;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 26px;
                    } 
                }
            }
        }
       
    }
}